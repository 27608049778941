<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <span>Близкие родственники</span>
      <b-dropdown size="sm" variant="primary" text="Добавить" right no-caret>
        <b-dropdown-item @click="handleAddRelative('Супруг')">Супруг</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Супруга')">Супруга</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Отец')">Отец</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Мать')">Мать</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Брат')">Брат</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Сестра')">Сестра</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Сын')">Сын</b-dropdown-item>
        <b-dropdown-item @click="handleAddRelative('Дочь')">Дочь</b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-if="relatives.length"
      class="table-responsive mb-5"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th />
            <th>Ф.И.О.</th>
            <th>Дата рождения</th>
            <th>Место работы/учёбы</th>
            <th>Должность</th>
            <th>Адрес проживания</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in relatives"
            :key="index"
          >
            <th>
              {{ item.type }}
            </th>
            <td>
              <b-form-input
                v-model="item.name"
                type="text"
                required
              />
            </td>
            <td>
              <date-picker
                v-model="item.birthday" type="date"
                value-type="YYYY-MM-DD"
                format="YYYY-MM-DD"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.work"
                type="text"
                required
              />
            </td>
            <td>
              <b-form-input
                v-model="item.position"
                type="text"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.address"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteRelative(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue2-datepicker';

export default {
  name: 'Relatives',
  props: ['list'],
  components: { datePicker },
  data() {
    return {
      relatives: this.list
    }
  },
  watch: {
    relatives: {
      handler(value) {
        this.$emit('onChange', value);
      },
      deep: true
    }
  },
  methods: {
    handleAddRelative(type) {
      this.relatives.push({
        type,
        name: '',
        birthday: null,
        work: '',
        position: '',
        address: ''
      })
    },
    handleDeleteRelative(index) {
      this.relatives.splice(index, 1);
    }
  }
}
</script>