<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <span>Трудовая деятельность (укажите в обратном хронологическом порядке 5 последних мест вашей работы)</span>
      <b-button
        size="sm"
        variant="primary"
        @click="handleAddWork"
      >
        Добавить
      </b-button>
    </div>
    <div
      v-if="works.length"
      class="table-responsive mb-5"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Период работы (от)</th>
            <th>Период работы (до)</th>
            <th>Наименование организации</th>
            <th>Должность</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in works"
            :key="index"
          >
            <th>
              <date-picker
                v-model="item.dateStart"
                type="month"
                value-type="format"
                format="YYYY-MM"
                :input-attr="{ required: 'required' }"
              />
            </th>
            <td>
              <date-picker
                v-model="item.dateEnd"
                type="month"
                value-type="format"
                format="YYYY-MM"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.name"
                type="text"
                required
              />
            </td>
            <td>
              <b-form-input
                v-model="item.position"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteWork(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue2-datepicker';

export default {
  name: 'Works',
  props: ['list'],
  components: { datePicker },
  data() {
    return {
      works: this.list
    }
  },
  watch: {
    works: {
      handler(value) {
        this.$emit('onChange', value);
      },
      deep: true
    }
  },
  methods: {
    handleAddWork() {
      this.works.push({
        dateStart: null,
        dateEnd: null,
        name: '',
        position: ''
      })
    },
    handleDeleteWork(index) {
      this.works.splice(index, 1);
    }
  }
}
</script>