<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row mb-1">
          <div class="col-6 col-sm-3">Ф.И.О</div>
          <div class="col-6 col-sm-9">
            {{ getFullName(profile.first_name, profile.last_name, profile.middle_name)}}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-6 col-sm-3">Country of Birth</div>
          <div class="col-6 col-sm-9">
            {{ profile.birthplace || '-' }}
          </div>
        </div>
        <template v-if="profile.type === 'full'">
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Дата рождения</div>
            <div class="col-6 col-sm-9">
              {{ profile.birthday ? $moment(profile.birthday).format('DD.MM.YYYY') : '-' }}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Адрес фактического места жительства</div>
            <div class="col-6 col-sm-9">{{ profile.address || '-' }}</div>
          </div>
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Гражданство</div>
            <div class="col-6 col-sm-9">{{ profile.citizenship || '-' }}</div>
          </div>
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Условия проживания</div>
            <div class="col-6 col-sm-9">{{ profile.living_condition || '-'}}</div>
          </div>
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Семейное положение</div>
            <div class="col-6 col-sm-9">{{ profile.marital_status || '-' }}</div>
          </div>
        </template>
        <template v-else-if="profile.type === 'short'">
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Country of Residence</div>
            <div class="col-6 col-sm-9">
              {{ profile.country_of_residence || '-' }}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-6 col-sm-3">Language(s)</div>
            <div class="col-6 col-sm-9">
              {{ profile.language || '-' }}
            </div>
          </div>
        </template>
      </div>
      <div class="col-lg-4 mt-4 mt-lg-0">
        <AvatarUploader
          v-if="employee"
          :avatar="employee.photo"
          :showMode="true"
        />
      </div>
    </div>
    <h4 class="mt-4 mb-2">КОНТАКТНЫЕ ДАННЫЕ</h4>
    <div class="row mb-1">
      <div class="col-6 col-sm-3">Контактный телефон</div>
      <div class="col-6 col-sm-9">{{ profile.phone || '-' }}</div>
    </div>
    <div v-if="profile.type === 'full'" class="row mb-1">
      <div class="col-6 col-sm-3">Контактный телефон (2)</div>
      <div class="col-6 col-sm-9">{{ profile.secondary_contact_phone || '-' }}</div>
    </div>
    <div class="row mb-1">
      <div class="col-6 col-sm-3">Email</div>
      <div class="col-6 col-sm-9">{{ profile.personal_email || '-' }}</div>
    </div>
    <div v-if="profile.type === 'short'" class="row mb-1">
      <div class="col-6 col-sm-3">Freelancer's account</div>
      <div class="col-6 col-sm-9">{{ profile.upwork_profile_link }}</div>
    </div>
    <template v-if="profile.type === 'full'">
      <h4 class="mt-4 mb-2">БЛИЗКИЕ РОДСТВЕННИКИ</h4>
      <div
        v-if="profile.relatives.length"
        class="table-responsive mb-5"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
            <tr>
              <th />
              <th>Ф.И.О.</th>
              <th>Дата рождения</th>
              <th>Место работы/учёбы</th>
              <th>Должность</th>
              <th>Адрес проживания</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in profile.relatives"
              :key="index"
            >
              <th>{{ item.type }}</th>
              <td>{{ item.name }}</td>
              <td>{{ $moment(item.birthday).format('DD.MM.YYYY') }}</td>
              <td>{{ item.work }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.address }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 class="mt-4 mb-2">ОБРАЗОВАНИЕ</h4>
      <div
        v-if="profile.educations.length"
        class="table-responsive mb-5"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
            <tr>
              <th>Период обучения</th>
              <th>Название</th>
              <th>Факультет</th>
              <th>Ученая степень</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in profile.educations"
              :key="index"
            >
              <td>{{ item.start_date }} - {{ item.end_date }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.department || '' }}</td>
              <td>{{ item.speciality || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 class="mt-4 mb-2">ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ</h4>
      <div
        v-if="profile.work_experiences.length"
        class="table-responsive mb-5"
      >
        <table class="table table-hover mb-0">
          <thead class="thead-light">
            <tr>
              <th>Период работы</th>
              <th>Наименование организации</th>
              <th>Должность</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in profile.work_experiences"
              :key="index"
            >
              <td>{{ $moment(item.start_date).format('MM.YYYY') }} - {{ $moment(item.end_date).format('MM.YYYY') }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.position }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
<!--    <template v-if="profile.type === 'short'">-->
<!--      <h4 class="mt-4 mb-2">Portfolio</h4>-->
<!--      <div-->
<!--        v-for="item in profile.portfolio"-->
<!--        :key="item.id"-->
<!--        class="mb-1"-->
<!--      >-->
<!--        <a-->
<!--          target="_blank"-->
<!--          :href="item.link"-->
<!--        >-->
<!--          {{ item.link }}-->
<!--        </a>-->
<!--      </div>-->
<!--    </template>-->
    <h4 class="mt-4 mb-2">СОЦИАЛЬНЫЕ СЕТИ</h4>
    <div
      v-for="item in profile.social_networks"
      :key="item.id"
      class="mb-1"
    >
      <a
        target="_blank"
        :href="item.link"
      >
        {{ item.link }}
      </a>
    </div>
    <template v-if="profile.type === 'full'">
      <div class="mt-4">
<!--        <div-->
<!--          v-if="profile && profile.pinfl"-->
<!--          class="d-flex align-items-center"-->
<!--        >-->
<!--          <span>ПИНФЛ: {{profile.pinfl}}</span>-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="profile && profile.passport"-->
<!--          class="d-flex align-items-center"-->
<!--        >-->
<!--          <span>Копия паспорта</span>-->
<!--          <i-->
<!--            class="bx bx-cloud-download font-size-20 ml-2"-->
<!--            @click="handleDownloadDocument(profile.passport)"-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="profile && profile.tax_number"-->
<!--          class="d-flex align-items-center"-->
<!--        >-->
<!--          <span>Копия ИНН</span>-->
<!--          <i-->
<!--            class="bx bx-cloud-download font-size-20 ml-2"-->
<!--            @click="handleDownloadDocument(profile.tax_number)"-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="profile && profile.international_passport"-->
<!--          class="d-flex align-items-center"-->
<!--        >-->
<!--          <span>Копия заграничого паспорта</span>-->
<!--          <i-->
<!--            class="bx bx-cloud-download font-size-20 ml-2"-->
<!--            @click="handleDownloadDocument(profile.international_passport)"-->
<!--          />-->
<!--        </div>-->
      </div>
    </template>
  </div>
</template>

<script>
import AvatarUploader from '@/components/AvatarUploader';

export default {
  name: 'ProfileView',
  components: {
    AvatarUploader
  },
  props: ['profile', 'employee'],
  methods: {
    getFullName(firstName, lastName, middleName) {
      return firstName && lastName && middleName ? `${firstName || ''} ${lastName || ''} ${middleName || ''}` : '-';
    },
    handleDownloadDocument(path) {
      this.$store.dispatch('employees/downloadProfileDocument', path);
    }
  }
}
</script>
