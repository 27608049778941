<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <span>Образование, когда и какие учебные заведения окончили</span>
      <b-dropdown size="sm" variant="primary" text="Добавить" right no-caret>
        <b-dropdown-item @click="handleAddEducation('secondary')">Среднее</b-dropdown-item>
        <b-dropdown-item @click="handleAddEducation('secondaryProf')">Среднее профессиональное</b-dropdown-item>
        <b-dropdown-item @click="handleAddEducation('higher')">Высшее</b-dropdown-item>
        <b-dropdown-item @click="handleAddEducation('extra')">Дополнительное</b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-if="education.secondary.length"
      class="table-responsive mb-5"
    >
      <h5>Среднее</h5>
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Год поступления</th>
            <th>Год окончания</th>
            <th>Название школы или лицея</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in education.secondary"
            :key="index"
          >
            <td>
              <date-picker
                v-model="item.dateStart"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <date-picker
                v-model="item.dateEnd"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.title"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteEducation('secondary', index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="education.secondaryProf.length"
      class="table-responsive mb-5"
    >
      <h5>Среднее профессиональное</h5>
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Год поступления</th>
            <th>Год окончания</th>
            <th>Название</th>
            <th>Факультет</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in education.secondaryProf"
            :key="index"
          >
            <td>
              <date-picker
                v-model="item.dateStart"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <date-picker
                v-model="item.dateEnd"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.title"
                type="text"
                required
              />
            </td>
            <td>
              <b-form-input
                v-model="item.department"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteEducation('secondaryProf', index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="education.higher.length"
      class="table-responsive mb-5"
    >
      <h5>Высшее</h5>
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Год поступления</th>
            <th>Год окончания</th>
            <th>Название</th>
            <th>Факультет</th>
            <th>Ученая степень</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in education.higher"
            :key="index"
          >
            <td>
              <date-picker
                v-model="item.dateStart"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <date-picker
                v-model="item.dateEnd"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.title"
                type="text"
                required
              />
            </td>
            <td>
              <b-form-input
                v-model="item.department"
                type="text"
                required
              />
            </td>
            <td>
              <b-form-input
                v-model="item.speciality"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteEducation('higher', index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="education.extra.length"
      class="table-responsive mb-5"
    >
      <h5>Дополнительное</h5>
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th>Год поступления</th>
            <th>Год окончания</th>
            <th>Описание(место прохождения, название курса)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in education.extra"
            :key="index"
          >
            <td>
              <date-picker
                v-model="item.dateStart"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <date-picker
                v-model="item.dateEnd"
                type="year"
                value-type="format"
                format="YYYY"
                :input-attr="{ required: 'required' }"
              />
            </td>
            <td>
              <b-form-input
                v-model="item.title"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteEducation('extra', index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue2-datepicker';

const getEducationFields = (type) => {
  const defaultFields = { dateStart: null, dateEnd: null, title: '' };
  if (type === 'secondary') {
    return { ...defaultFields, type: 'Среднее' };
  }
  if (type === 'secondaryProf') {
    return { ...defaultFields, department: '', type: 'Среднее профессиональное' };
  }
  if (type === 'higher') {
    return { ...defaultFields, department: '', speciality: '', type: 'Высшее' };
  }
  if (type === 'extra') {
    return { ...defaultFields, type: 'Дополнительное' };
  }

  return defaultFields;
}

export default {
  title: 'Educations',
  components: { datePicker },
  props: ['data'],
  data() {
    return {
      education: {
        secondary: [...this.data.secondary],
        secondaryProf: [...this.data.secondaryProf],
        higher: [...this.data.higher],
        extra: [...this.data.extra]
      }
    }
  },
  watch: {
    education: {
      handler(value) {
        this.$emit('onChange', value);
      },
      deep: true
    }
  },
  methods: {
    handleAddEducation(type) {
      this.education[type].push(getEducationFields(type));
    },
    handleDeleteEducation(type, index) {
      this.education[type].splice(index, 1);
    }
  }
}
</script>