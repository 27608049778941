<template>
  <b-list-group>
    <b-list-group-item
      v-for="(email, index) in emails"
      :key="email.email"
      class="d-flex"
    >
      <div class="text-dark mr-3">{{ index + 1 }}.</div>
      <div>
        <span class="text-dark d-block mb-1">{{ email.email }}</span>
        <p class="mb-0">{{ email.description }}</p>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'EmailsTab',
  props: ['emails']
}
</script>
