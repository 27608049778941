<template>
  <b-list-group>
    <b-list-group-item
      v-for="(equipment, index) in equipments"
      :key="equipment.inventory_code"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center text-dark mr-3">
        <div>{{ index + 1 }}.</div>
        <div class="ml-2">{{ equipment.description }}</div>
      </div>
      <div class="bg-primary text-light px-2 py-1 rounded">
        {{ equipment.inventory_code }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'EquipmentTab',
  props: ['equipments']
}
</script>
