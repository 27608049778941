<template>
  <div>
    <Contracts
      :employeeId="employee.uid"
      :contracts="employee.contracts"
    />
    <div class="mt-5">
      <Documents
        :employeeId="employee.uid"
        :contracts="employee.contracts"
        :documents="employee.document_links"
      />
    </div>
  </div>
</template>

<script>
import Contracts from './contracts.vue';
import Documents from './documents.vue';

export default {
  name: 'ContractsTab',
  props: ['employee'],
  components: {
    Contracts,
    Documents
  }
}
</script>
