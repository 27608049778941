import startCase from 'lodash/startCase';
import isBoolean from 'lodash/isBoolean';
import { showErrorToast } from './toasts';

export function validateForm(form) {
  let isValid = true;

  const formEntries = Object.entries(form);
  for (let i = 0; i < formEntries.length; i += 1) {
    const [key, value] = formEntries[i];
    if (Array.isArray(value) && value.length === 0) {
      showErrorToast(`${startCase(key)} is required`);
      isValid = false;
      break;
    }

    if (!isBoolean(value) && !value) {
      showErrorToast(`${startCase(key)} is required`);
      isValid = false;
      break;
    }
  }

  return isValid;
}