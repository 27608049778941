<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <span>Социальные сети</span>
      <b-button
        size="sm"
        variant="primary"
        @click="handleAddLink"
      >
        Добавить
      </b-button>
    </div>
    <div class="mb-3">
      <b-form-checkbox
        v-model="dontHaveSocial"
      >
        Не буду указывать
      </b-form-checkbox>
    </div>
    <div
      v-if="links.length"
      class="table-responsive mb-5"
    >
      <table class="table table-hover mb-0">
        <tbody>
          <tr
            v-for="(item, index) in links"
            :key="index"
          >
            <th style="width: 160px;">Ссылка на профиль</th>
            <td>
              <b-form-input
                v-model="links[index]"
                type="text"
                required
              />
            </td>
            <td style="width: 44px">
              <i
                class="bx bx-trash text-danger font-size-20"
                @click="handleDeleteWork(index)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
  props: ['list'],
  data() {
    return {
      links: this.list,
      dontHaveSocial: !this.list.length
    }
  },
  watch: {
    dontHaveSocial(value) {
      if (value) {
        this.links = []
      }
    },
    links: {
      handler(value) {
        if (value.length) {
          this.dontHaveSocial = false;
        } else {
          this.dontHaveSocial = true;
        }
        this.$emit('onChange', value);
      },
      deep: true
    }
  },
  methods: {
    handleAddLink() {
      this.links.push('');
    },
    handleDeleteWork(index) {
      this.links.splice(index, 1);
    }
  }
}
</script>