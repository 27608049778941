<template>
  <div class="profile">
    <ProfileRequestForm
      :modalId="modalId"
      :email="employee.email"
      :employeeId="employee.uid"
    />
    <div
      v-if="!isEditMode"
    >
      <div class="mb-4 w-100">
        <div class="row">
          <div class="col-md-8">
            <div class="profile-version">
              <div>Profile Version</div>
              <b-form-select
                  id="profileVersion"
                  v-model="form.profileVersion"
                  :options="form.profileVersionOptions"
                  @change="handleProfileVersionChange(form.profileVersion)"
              />
              <b-button
                  v-if="form.profileVersion != this.employee.profile.id"
                  class="ml-2"
                  variant="primary"
                  @click="setProfileVersion(form.profileVersion)"
              >
                <i class="fas fa-save"></i> Save as current
              </b-button>
              <div v-else class="alert-success p-2 ml-2">
                <i class="fas fa-check-circle"></i> Current version
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="profile-action">
              <b-button
                  v-b-modal="modalId"
                  variant="primary"
              >
                Send fill profile request
              </b-button>
              <b-button
                  class="ml-2"
                  variant="primary"
                  @click="() => this.isEditMode = true"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
<!--        <div class="row col-6 align-items-center profile-version">-->
<!--          <div>Profile Version</div>-->
<!--          <b-form-select-->
<!--                  id="profileVersion"-->
<!--                  v-model="form.profileVersion"-->
<!--                  :options="form.profileVersionOptions"-->
<!--                  @change="handleProfileVersionChange(form.profileVersion)"-->
<!--          />-->
<!--          <b-button-->
<!--                  v-if="form.profileVersion != this.employee.profile.id"-->
<!--                  class="ml-2"-->
<!--                  variant="primary"-->
<!--                  @click="setProfileVersion(form.profileVersion)"-->
<!--          >-->
<!--            <i class="fas fa-save"></i> Save as current-->
<!--          </b-button>-->
<!--          <div v-else class="alert-success p-2 ml-2">-->
<!--            <i class="fas fa-check-circle"></i> Current version-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row col-6 justify-content-end">-->
<!--          <b-button-->
<!--                  v-b-modal="modalId"-->
<!--                  variant="primary"-->
<!--          >-->
<!--            Send fill profile request-->
<!--          </b-button>-->
<!--          <b-button-->
<!--                  class="ml-2"-->
<!--                  variant="primary"-->
<!--                  @click="() => this.isEditMode = true"-->
<!--          >-->
<!--            Edit-->
<!--          </b-button>-->
<!--        </div>-->
      </div>
      <ProfileView
        v-if="profile"
        :profile="profile"
        :employee="employee"
      />
    </div>
    <b-form
      v-else
      @submit="handleFormSubmit"
    >
      <div class="row">
        <div class="col-lg-8">
          <div class="row">
            <b-form-group
              label="Имя"
              label-for="first-name"
              class="col-lg required"
            >
              <b-form-input
                id="first-name"
                v-model="form.firstName"
                type="text"
                placeholder="Введите имя"
                required
              />
            </b-form-group>
            <b-form-group
              label="Фамилия"
              label-for="last-name"
              class="col-lg required"
            >
              <b-form-input
                id="last-name"
                v-model="form.lastName"
                type="text"
                placeholder="Введите фамилию"
                required
              />
            </b-form-group>
            <b-form-group
              label="Отчество"
              label-for="patronymic"
              class="col-lg required"
            >
              <b-form-input
                id="patronymic"
                v-model="form.patronymic"
                type="text"
                placeholder="Введите отчество"
                required
              />
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group
              label="Дата рождения"
              label-for="dob"
              class="col-lg-4 required"
              v-if="type === 'full'"
            >
              <date-picker
                v-model="form.dob" type="date"
                value-type="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :input-attr="{ required: 'required', id: 'dob'}"
              />
            </b-form-group>
            <b-form-group
              label="Country of Birth"
              label-for="born-place"
              class="col-lg-8 required"
            >
              <b-form-input
                id="born-place"
                v-model="form.bornPlace"
                type="text"
                placeholder="Город, область, республика"
                required
              />
            </b-form-group>
            <b-form-group
              v-if="type === 'short'"
              label="Country of Residence"
              label-for="born-place"
              class="col-lg-8 required"
            >
              <b-form-input
                id="born-place"
                v-model="form.countryOfResidence"
                type="text"
                placeholder="Город, область, республика"
                required
              />
            </b-form-group>
          </div>
        </div>
        <div class="col-lg-4">
          <AvatarUploader
            v-if="profile"
            :avatar="profile.photo"
            @onChange="handleAvatarChange"
          />
        </div>
      </div>
      <template v-if="profile.type === 'full'">
        <div class="row">
          <b-form-group
            label="Адрес фактического места жительства"
            label-for="address"
            class="col-lg-8 required"
          >
            <b-form-input
              id="address"
              v-model="form.address"
              type="text"
              placeholder="Город, улица, дом, квартира"
              required
            />
          </b-form-group>
          <b-form-group
            label="Условия проживания"
            label-for="сonditions"
            class="col-lg-4 required"
          >
            <b-form-select
              id="сonditions"
              v-model="form.accommodationСonditions"
              :options="conditionOptions"
              required
            />
          </b-form-group>
        </div>
      </template>
      <div class="row">
        <b-form-group
          label="1. Контактный телефон"
          label-for="phone1"
          class="col-lg"
        >
          <b-form-input
            id="phone1"
            v-model="form.phone1"
            type="text"
          />
        </b-form-group>
        <b-form-group
          v-if="profile.type === 'full'"
          label="2. Контактный телефон"
          label-for="phone2"
          class="col-lg"
        >
          <b-form-input
            id="phone2"
            v-model="form.phone2"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Личный Email"
          label-for="email"
          class="col-lg"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
          />
        </b-form-group>
        <b-form-group
          label="Language"
          label-for="language"
          class="col-lg"
        >
          <b-form-input
            id="language"
            v-model="form.language"
            type="text"
          />
        </b-form-group>
      </div>
      <div v-if="profile.type === 'full'" class="mb-3">
        2. Контактный телефон - Укажите один личный контактный телефон близкого человека (супруг, супруга, отец, мать, брат, сестра, сын, дочь, близкий друг), через которого можно с Вами связаться в экстренных случаях
      </div>
      <div v-else-if="profile.type === 'short'" class="row">
        <b-form-group
          label="CV"
          label-for="cv"
          class="col-lg"
        >
          <b-form-input
            id="cv"
            v-model="form.curriculum"
            type="text"
            placeholder="Please type here some info about you in general"
          />
        </b-form-group>
        <b-form-group
          label="Freelancer's account"
          label-for="upworkProfileLink"
          class="col-lg"
        >
          <b-form-input
            id="upworkProfileLink"
            v-model="form.upworkProfileLink"
            type="text"
            placeholder=""
          />
        </b-form-group>
      </div>
      <template v-if="profile.type === 'full'">
        <div class="row">
          <b-form-group
            label="Гражданство"
            label-for="citizenship"
            class="col-lg-8 required"
          >
            <b-form-input
              id="citizenship"
              v-model="form.citizenship"
              type="text"
              required
            />
          </b-form-group>
          <b-form-group
            label="Семейное положение"
            label-for="maritalStatus"
            class="col-lg-4 required"
          >
            <b-form-select
              id="maritalStatus"
              v-model="form.maritalStatus"
              :options="maritalStatusOptions"
              required
            />
          </b-form-group>
        </div>
        <Relatives
          :list="form.relatives"
          @onChange="handleRelativesChange"
        />
        <Educations
          :data="form.educations"
          @onChange="handleEducationChange"
        />
        <Works
          :list="form.works"
          @onChange="handleWorksChange"
        />
      </template>
<!--      <Portfolios-->
<!--        v-if="profile.type === 'short'"-->
<!--        :list="form.portfolios || []"-->
<!--        @onChange="handlePortfoliosChange"-->
<!--      />-->
      <SocialLinks
        :list="form.socialLinks"
        @onChange="handleSocialLinksChange"
      />
      <div v-if="profile.type === 'full'" class="row">
<!--        {{ form.passport }}-->
<!--        <b-form-group-->
<!--          label="Копия паспорта"-->
<!--          label-for="passport"-->
<!--          class="col-lg"-->
<!--        >-->
<!--          <b-form-file-->
<!--            id="passport"-->
<!--            v-model="form.passport"-->
<!--            placeholder="Выберите файл"-->
<!--          />-->
<!--          <div class="mt-2">* Копия паспорта - главная страница с фото + разворот с пропиской</div>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="ПИНФЛ"-->
<!--          label-for="pinfl"-->
<!--          class="col-lg"-->
<!--        >-->
<!--          <b-form-input-->
<!--            id="pinfl"-->
<!--            maxlength="14"-->
<!--            v-model="form.pinfl"-->
<!--            placeholder="ПИНФЛ"-->
<!--            min="10000000000000"-->
<!--            max="99999999999999"-->
<!--          />-->
<!--          <div class="mt-2">* ПИНФЛ - 14 цифр</div>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="Копия ИНН"-->
<!--          label-for="inn"-->
<!--          class="col-lg"-->
<!--        >-->
<!--          <b-form-file-->
<!--            id="inn"-->
<!--            v-model="form.inn"-->
<!--            placeholder="Выберите файл"-->
<!--            :required="!profile.tax_number"-->
<!--          />-->
<!--          <div class="mt-2">* Копия ИНН - официальный бланк или скриншот с официального сайта Республики Узбекистан</div>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="Копия заграничого паспорта (при наличии)"-->
<!--          label-for="foreign-passport"-->
<!--          class="col-lg"-->
<!--        >-->
<!--          <b-form-file-->
<!--            id="foreign-passport"-->
<!--            v-model="form.fPassport"-->
<!--            placeholder="Выберите файл"-->
<!--          />-->
<!--          <div class="mt-2">* Копия заграничого паспорта - главная страница с фото</div>-->
<!--        </b-form-group>-->
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          class="mr-2"
          variant="primary"
          @click="() => this.isEditMode = false"
        >
          Back
        </b-button>
        <b-button
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import Works from './works.vue';
import ProfileView from './view.vue';
import Relatives from './relatives.vue';
import Educations from './educations.vue';
import SocialLinks from './social_links.vue';
// import Portfolios from './portfolios.vue';
import AvatarUploader from '@/components/AvatarUploader';
import ProfileRequestForm from './profile_request_form.vue';

export default {
  name: 'ProfileTab',
  components: {
    datePicker,
    Works,
    Relatives,
    Educations,
    ProfileView,
    SocialLinks,
    // Portfolios,
    AvatarUploader,
    ProfileRequestForm
  },
  props: ['employee'],
  data() {
    return {
      avatar: null,
      isEditMode: false,
      modalId: 'profile-request-form',
      conditionOptions: [
        { value: 'в квартире с родителями', text: 'в квартире с родителями' },
        { value: 'отдельная квартира', text: 'отдельная квартира' },
        { value: 'собственный дом', text: 'собственный дом' },
        { value: 'арендуемая квартира', text: 'арендуемая квартира' },
        { value: 'прочее', text: 'прочее' }
      ],
      maritalStatusOptions: [
        { value: 'не замужем / не женат', text: 'не замужем / не женат' },
        { value: 'разведен / разведена', text: 'разведен / разведена' },
        { value: 'замужем / женат', text: 'замужем / женат' },
        { value: 'состою в гражданском браке', text: 'состою в гражданском браке' },
      ],
      profileVersionOptions: null,
      form: {
        firstName: '',
        lastName: '',
        patronymic: '',
        dob: null,
        bornPlace: '',
        address: '',
        accommodationСonditions: null,
        phone1: '',
        phone2: '',
        email: '',
        citizenship: '',
        maritalStatus: null,
        profileVersion: null,
        relatives: [],
        educations: {
          secondary: [],
          secondaryProf: [],
          higher: [],
          extra: []
        },
        works: [],
        socialLinks: [],
        passport: null,
        inn: null,
        pinfl: null,
        fPassport: null,
        countryOfResidence: null,
        curriculum: null,
        language: null,
        upworkProfileLink: null,
        portfolios: null
      },
      type: this.employee.profile ? this.employee.profile.type : null
    }
  },
  async mounted() {
    await this.$store.dispatch('employees/getProfileById', this.employee.profile.id);
  },
  beforeDestroy() {
    this.$store.dispatch('employees/clearProfile');
  },
  computed: {
    profile() {
      return this.$store.state.employees.profile;
    }
  },
  watch: {
    async profile(value) {
      if (value) {
        this.form.firstName = value.first_name;
        this.form.lastName = value.last_name;
        this.form.patronymic = value.middle_name;
        this.form.dob = value.birthday;
        this.form.bornPlace = value.birthplace;
        this.form.countryOfResidence = value.country_of_residence;
        this.form.address = value.address;
        this.form.accommodationСonditions = value.living_condition;
        this.form.phone1 = value.phone;
        this.form.phone2 = value.secondary_contact_phone;
        this.form.pinfl = value.pinfl;
        this.form.email = value.personal_email;
        this.form.citizenship = value.citizenship;
        this.form.maritalStatus = value.marital_status;
        this.form.profileVersion = this.profile.id;
        this.form.profileVersionOptions = Object.keys(value.versions).map((key) => ({
          text: value.versions[key],
          value: key
        })).reverse();
        this.form.curriculum = value.curriculum;
        this.form.upworkProfileLink = value.upwork_profile_link;
        this.form.language = value.language;
        this.form.relatives = value.relatives.map((item) => ({
          type: item.type,
          name: item.name,
          birthday: item.birthday,
          work: item.work,
          position: item.position,
          address: item.address
        }));
        this.form.works = value.work_experiences.map((item) => ({
          dateStart: item.start_date,
          dateEnd: item.end_date,
          name: item.title,
          position: item.position
        }));
        this.form.socialLinks = value.social_networks.map(({ link }) => link);
        this.form.educations = value.educations.reduce((acc, item) => {
          const defaultFields = {
            type: item.type,
            dateStart: item.start_date,
            dateEnd: item.end_date,
            title: item.title
          }
          if (item.type === 'Среднее') {
            acc.secondary.push({
              ...defaultFields
            });
          }
          if (item.type === 'Среднее профессиональное') {
            acc.secondaryProf.push({
              ...defaultFields,
              department: item.department
            });
          }
          if (item.type === 'Высшее') {
            acc.higher.push({
              ...defaultFields,
              department: item.department,
              speciality: item.speciality
            });
          }
          if (item.type === 'Дополнительное') {
            acc.extra.push({
              ...defaultFields
            });
          }

          return acc;
        }, {
          secondary: [],
          secondaryProf: [],
          higher: [],
          extra: []
        });
      }
    }
  },
  methods: {
    handleAvatarChange(image) {
      this.avatar = image;
    },
    handleRelativesChange(relatives) {
      this.form.relatives = [ ...relatives ];
    },
    handleEducationChange(education) {
      this.form.educations = { ...education };
    },
    handleWorksChange(works) {
      this.form.works = [...works];
    },
    handlePortfoliosChange(portfolios) {
      this.form.portfolios = [...portfolios];
    },
    handleSocialLinksChange(socialLinks) {
      this.form.socialLinks = [...socialLinks];
    },
    handleProfileVersionChange(versionId) {
      this.$store.dispatch('employees/getProfileById', versionId)
      this.form.profileVersion = versionId
    },
    setProfileVersion(versionId){
      this.$store.dispatch('employees/setProfileVersionById', versionId)
    },
    async handleFormSubmit(event) {
      event.preventDefault();
      let haveErrors = false
      if (this.avatar) {
        await this.$store.dispatch('employees/uploadProfileAvatar', {
          id: this.profile.id,
          data: this.$convertObjectToFormData({
            document: this.avatar,
            name: this.avatar.name
          })
        });
      }

      if (this.form.passport) {
        await this.$store.dispatch('employees/uploadProfilePassport', {
          id: this.profile.id,
          data: this.$convertObjectToFormData({
            document: this.form.passport,
            name: this.form.passport.name
          })
        })
          .then((response) => {
            if (!response || response.code !== 200) {
              haveErrors = true
            }
          });
      }

      if (this.form.fPassport) {
        await this.$store.dispatch('employees/uploadProfileInternationalPassport', {
          id: this.profile.id,
          data: this.$convertObjectToFormData({
            document: this.form.fPassport,
            name: this.form.fPassport.name
          })
        })
          .then((response) => {
            if (!response || response.code !== 200) {
              haveErrors = true
            }
          });
      }

      if (this.form.inn) {
        await this.$store.dispatch('employees/uploadProfileTaxNumber', {
          id: this.profile.id,
          data: this.$convertObjectToFormData({
            document: this.form.inn,
            name: this.form.inn.name
          })
        })
          .then((response) => {
            if (!response || response.code !== 200) {
              haveErrors = true
            }
          });
      }

      const fData = new FormData();
      fData.append('first_name', this.form.firstName);
      fData.append('last_name', this.form.lastName);
      fData.append('middle_name', this.form.patronymic);
      if (this?.form?.phone1 && this.form.phone1.length > 0) {
        fData.append('phone', this.form.phone1);
      }
      if (this?.form?.email && this.form.email.length > 0) {
        fData.append('personal_email', this.form.email);
      }
      fData.append('birthplace', this.form.bornPlace);
      if (this.profile.type === 'short') {
        fData.append('type', 'short');
        fData.append('country_of_residence', this.form.countryOfResidence);
        fData.append('curriculum', this.form.curriculum);
        fData.append('upwork_profile_link', this.form.upworkProfileLink);
        fData.append('language', this.form.language);
      } else if (this.profile.type === 'full') {
        fData.append('birthday', this.form.dob);
        fData.append('address', this.form.address);
        fData.append('living_condition', this.form.accommodationСonditions);
        if (this?.form?.phone2 && this.form.phone2.length > 0) {
          fData.append('secondary_contact_phone', this.form.phone2);
        }
        if (this?.form?.pinfl && this.form.pinfl.length > 0) {
          fData.append('pinfl', this.form.pinfl);
        }
        fData.append('citizenship', this.form.citizenship);
        fData.append('marital_status', this.form.maritalStatus);
        fData.append('do_not_want_indicate_social_networks', this.form.socialLinks.length ? 1 : 0);
        fData.append('data_responsibility', this.profile.data_responsibility);

        this.form.relatives.forEach((item, index) => {
          fData.append(`relatives[${index}][type]`, item.type);
          fData.append(`relatives[${index}][name]`, item.name);
          fData.append(`relatives[${index}][birthday]`, item.birthday);
          fData.append(`relatives[${index}][work]`, item.work);
          fData.append(`relatives[${index}][position]`, item.position);
          fData.append(`relatives[${index}][address]`, item.address);
        });

        this.form.works.forEach((item, index) => {
          fData.append(`work_experiences[${index}][title]`, item.name);
          fData.append(`work_experiences[${index}][end_date]`, item.dateEnd);
          fData.append(`work_experiences[${index}][position]`, item.position);
          fData.append(`work_experiences[${index}][start_date]`, item.dateStart);
        });

        let index = 0;
        [...this.form.educations.secondary, ...this.form.educations.extra].forEach((item) => {
          fData.append(`educations[${index}][end_date]`, item.dateEnd);
          fData.append(`educations[${index}][start_date]`, item.dateStart);
          fData.append(`educations[${index}][title]`, item.title);
          fData.append(`educations[${index}][type]`, item.type);
          index += 1;
        });

        [...this.form.educations.secondaryProf].forEach((item) => {
          fData.append(`educations[${index}][end_date]`, item.dateEnd);
          fData.append(`educations[${index}][start_date]`, item.dateStart);
          fData.append(`educations[${index}][title]`, item.title);
          fData.append(`educations[${index}][type]`, item.type);
          fData.append(`educations[${index}][department]`, item.department);
          index += 1;
        });

        [...this.form.educations.higher].forEach((item) => {
          fData.append(`educations[${index}][end_date]`, item.dateEnd);
          fData.append(`educations[${index}][start_date]`, item.dateStart);
          fData.append(`educations[${index}][title]`, item.title);
          fData.append(`educations[${index}][type]`, item.type);
          fData.append(`educations[${index}][department]`, item.department);
          fData.append(`educations[${index}][speciality]`, item.speciality);
          index += 1;
        });
      }
      this.form.socialLinks.forEach((item, index) => {
        fData.append(`social_networks[${index}][link]`, item);
      });
      await this.$store.dispatch('employees/updateProfile', {
        id: this.profile.id,
        data: fData
      })
        .then(async (response) => {
          if (!response || response.code !== 200) {
            haveErrors = true
          } else {
            await this.$store.dispatch('employees/getProfileById', response.data.id)
          }
        })
      if (!haveErrors) {
        this.isEditMode = false;
        // this.$store.dispatch('employees/getProfileById', this.employee.profile.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    i {
      cursor: pointer;
    }
  }
  .profile-version{
    display: flex;
    align-items: center;
    div{
      margin: 0;
    }
    select{
      width: auto;
      margin-left: 10px;
    }
    .alert-success{
      border-radius: 0.25rem;
    }
  }
  .profile-action {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    .profile-version {
      flex-direction: row;
    }
    .profile-action {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  @media (max-width: 575px) {
    .profile-version {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      select {
        margin-left: 0;
      }
      .alert-success {
        margin-left: 0 !important;
        margin-bottom: 5px;
      }
    }
  }
</style>
