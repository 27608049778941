<template>
  <div
    v-if="accounts.length"
    class="table-responsive"
  >
    <table class="table table-hover mb-0">
      <thead class="thead-light">
        <tr>
          <th class="id-column"><div>#</div></th>
          <th>Service</th>
          <th>Description</th>
          <th>Status</th>
          <th>Registered email</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in accounts"
          :key="item.id"
        >
          <th class="id-column"><div>{{ index + 1 }}</div></th>
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.role }}</td>
          <td>{{ item.email }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AccountsTab',
  props: ['accounts']
}
</script>
