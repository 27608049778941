<template>
  <Layout v-load="loading">
    <div>
      <h2 v-if="employee" class="mb-4 mt-3">{{ employee.name }}</h2>
      <b-tabs
        content-class="p-3 text-muted mb-3"
        v-model="tabIndex"
        id="tabs"
        class="tabs-block"
        v-arrow="true"
      >
        <b-tab>
          <template v-slot:title>
            <span>Details</span>
          </template>
          <DetailsTab
            v-if="tabIndex === 0 && employee"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Profile</span>
          </template>
          <ProfileTab
            v-if="tabIndex === 1 && employee"
            :employee="employee"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Contracts</span>
          </template>
          <ContractsTab
            v-if="tabIndex === 2 && employee"
            :employee="employee"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Accounts</span>
          </template>
          <AccountsTab
            v-if="tabIndex === 3 && employee"
            :accounts="employee.accounts"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Emails</span>
          </template>
          <EmailsTab
            v-if="tabIndex === 4 && employee"
            :emails="employee.emails"
          />
        </b-tab>
         <b-tab>
          <template v-slot:title>
            <span>Equipment</span>
          </template>
          <EquipmentTab
            v-if="tabIndex === 5 && employee"
            :equipments="employee.equipments"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Library</span>
          </template>
          <LibraryTab
            v-if="tabIndex === 6 && employee"
            :books="employee.books"
          />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>Notes</span>
          </template>
          <NotesTab
            v-if="tabIndex === 7 && employee"
            :employeeId="employee.uid"
            :notes="employee.notes"
          />
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main.vue';
import {
  DetailsTab,
  ProfileTab,
  ContractsTab,
  NotesTab,
  AccountsTab,
  EmailsTab,
  LibraryTab,
  EquipmentTab,
} from './tabs';

export default {
  name: 'Employee',
  components: {
    Layout,
    NotesTab,
    ProfileTab,
    EmailsTab,
    DetailsTab,
    LibraryTab,
    AccountsTab,
    EquipmentTab,
    ContractsTab,
  },
  data() {
    return {
      tabIndex: 0,
      employeeId: null
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      this.employeeId = id;
      await this.$store.dispatch('employees/clearEmployee');
      await this.$store.dispatch('employees/getEmployeeById', id);
    }
  },
  beforeDestroy() {
    this.$store.dispatch('employees/clearEmployee');
  },
  computed: {
    employee() {
      return this.$store.getters["employees/employee"];
    },
    loading() {
      return this.$store.state.employees.loading || this.$store.state.settings.loading;
    }
  },
  created() {
    this.tabIndex = +this.$route.query.tab || 0
  },
  watch: {
    tabIndex() {
      this.$router.replace({ query: {tab: this.tabIndex} }).catch(() => false)
    }
  },
}
</script>

<style lang="scss">
.tabs-block {
  background-color: #FFFFFF;
  border-radius: 10px;
  position: relative;
}
</style>
