<template>
  <div>
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
      @hidden="clearForm"
    >
      <b-form
        @submit="handleSubmit"
      >
        <b-form-group
          label="Start Date"
          label-for="start-date"
          class="required"
        >
          <b-input-group>
            <date-picker
              v-model="form.startDate"
              type="date"
              value-type="YYYY-MM-DD"
              format="DD.MM.YYYY"
              :disabled-date="isStartContractDayDisabled"
              :input-attr="{ required: 'required', id: 'startDate'}"
              placeholder="Select Date"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="End Date"
          label-for="end-date"
          class="required"
        >
          <b-input-group>
            <date-picker
              v-model="form.endDate"
              type="date"
              value-type="YYYY-MM-DD"
              format="DD.MM.YYYY"
              :disabled="!form.startDate"
              :disabled-date="getDateTo()"
              :input-attr="{ required: 'required', id: 'endDate'}"
              placeholder="Select Date"
            />
<!--            <b-form-input-->
<!--              id="end-date-input"-->
<!--              v-model="form.endDate"-->
<!--              type="date"-->
<!--              placeholder="Select Date"-->
<!--              autocomplete="off"-->
<!--            ></b-form-input>-->
<!--            <b-input-group-append>-->
<!--              <b-form-datepicker-->
<!--                id="end-date"-->
<!--                v-model="form.endDate"-->
<!--                button-only-->
<!--                right-->
<!--                aria-controls="end-date-input"-->
<!--              />-->
<!--            </b-input-group-append>-->
          </b-input-group>
        </b-form-group>
          <b-form-group
              label="Type"
              label-for="type"
          >
              <b-form-select
                  id="type"
                  v-model="form.type"
                  :options="types"
                  text-field="title"
                  value-field="value"
              ></b-form-select>
          </b-form-group>
        <p v-if="checkMergeDates" class="text-warning">You have an intersection between dates</p>
        <b-form-group>
          <b-form-checkbox
              :disabled="form.onPprobation"
              v-model="form.change_vacation_hour"
          >
            Set available vacation hours
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
            v-if="form.change_vacation_hour"
            label="Vacation hours"
            label-for="end-date"
        >
          <b-input-group>
            <b-form-input type="number" v-model="form.total_vacation_hours" placeholder="Write vacation hours"></b-form-input>
            <!--            <b-form-input-->
            <!--              id="end-date-input"-->
            <!--              v-model="form.endDate"-->
            <!--              type="date"-->
            <!--              placeholder="Select Date"-->
            <!--              autocomplete="off"-->
            <!--            ></b-form-input>-->
            <!--            <b-input-group-append>-->
            <!--              <b-form-datepicker-->
            <!--                id="end-date"-->
            <!--                v-model="form.endDate"-->
            <!--                button-only-->
            <!--                right-->
            <!--                aria-controls="end-date-input"-->
            <!--              />-->
            <!--            </b-input-group-append>-->
          </b-input-group>
        </b-form-group>
<!--        <b-form-group-->
<!--          v-if="formMode === 'create'"-->
<!--          label="Archived At"-->
<!--          label-for="archived-date"-->
<!--        >-->
<!--          <b-input-group>-->
<!--            <b-form-input-->
<!--              id="archived-date-input"-->
<!--              v-model="form.archivedDate"-->
<!--              type="date"-->
<!--              placeholder="Select Date"-->
<!--              autocomplete="off"-->
<!--            ></b-form-input>-->
<!--            <b-input-group-append>-->
<!--              <b-form-datepicker-->
<!--                id="archived-date"-->
<!--                v-model="form.archivedDate"-->
<!--                button-only-->
<!--                right-->
<!--                aria-controls="archived-date-input"-->
<!--              />-->
<!--            </b-input-group-append>-->
<!--          </b-input-group>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="Start Date"-->
<!--          label-for="start-date"-->
<!--        >-->
<!--          <b-form-datepicker id="start-date" v-model="form.startDate" class="mb-2"></b-form-datepicker>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="End Date"-->
<!--          label-for="end-date"-->
<!--        >-->
<!--          <b-form-datepicker id="end-date" v-model="form.endDate" class="mb-2"></b-form-datepicker>-->
<!--        </b-form-group>-->
<!--        <b-form-group-->
<!--          label="Archived At"-->
<!--          label-for="archived-date"-->
<!--        >-->
<!--          <b-form-datepicker id="archived-date" v-model="form.archivedDate" class="mb-2"></b-form-datepicker>-->
<!--        </b-form-group>-->
        <b-form-group>
          <b-form-checkbox
              v-model="form.onPprobation"
          >
            On probation
          </b-form-checkbox>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
          >
            {{ buttonText }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h4>Contracts</h4>
      <b-button variant="primary" @click="onAddButtonClick">Add Contract</b-button>
    </div>
    <div
      v-if="list"
      class="table-responsive"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th class="id-column"><div>#</div></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>On Probation</th>
            <th>Vacation Hours</th>
              <th>Type</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="item.id"
          >
            <th class="id-column"><div>{{ index + 1 }}</div></th>
            <td>{{ $moment(item.start_date).format('DD.MM.YYYY') }}</td>
            <td>{{ $moment(item.end_date).format('DD.MM.YYYY') }}</td>
            <td>{{ status(item) }}</td>
            <td>{{ item.on_probation ? 'Yes' : 'No' }}</td>
            <td>{{ item.vacation_hours?.total_value ?? '-' }}</td>
              <td>{{ item.type ? item.type : '-' }}</td>
            <td class="actions-buttons">
              <div>
                <i
                  class="bx bx-pencil text-warning font-size-20 mr-3"
                  @click="onEditIconClick(item)"
                />
                <i
                  v-if="item.dismissed_date"
                  class="bx bx-show text-primary font-size-20 mr-3"
                  @click="onShowIconClick(item)"
                />
                <i
                  v-if="!item.dismissed_date"
                  class="bx bx-user-x text-danger font-size-20 mr-3"
                  @click="onDismissIconClick(item)"
                />
                <i
                  class="bx bx-trash text-danger font-size-20"
                  @click="onDeleteIconClick(item.id)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DismissModal :dismissData="dismissData" />
  </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import { FORM_MODE } from '@/utils/constants';
import { validateForm } from '@/utils/validation';

export default {
  name: 'Contracts',
  props: ['employeeId', 'contracts'],
  components: {
    datePicker,
    DismissModal: () => ({
      component: import('./dismiss')
    })
  },
  data() {
    return {
      formMode: null,
      currentContractId: null,
      modalId: 'contract-modal',
      form: {
        startDate: null,
        endDate: null,
        archivedDate: null,
        onPprobation: false,
        total_vacation_hours: null,
        change_vacation_hour: false,
          type: null
      },
        types: [
            {id: 1, title: 'Main', value: 'main'},
            {id: 2, title: 'Freelancer', value: 'freelancer'}
        ],
      dismissData: null
    }
  },
  computed: {
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Contract' : 'Edit Contract';
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Create' : 'Save';
    },
    list() {
      const result = this.contracts ? this.contracts.map(item => item) : []
      return result.sort((a, b) => a.start_date > b.start_date ? 1 : -1)
    },
    checkMergeDates() {
      return this.list
        .filter(item => item.id !== this.currentContractId && !item.dismissed_date)
        .filter((e) => (new Date(e.start_date) >= new Date(this.form.startDate) && new Date(e.start_date) <= new Date(this.form.endDate)) || new Date(e.end_date) >= new Date(this.form.startDate) && new Date(e.start_date) <= new Date(this.form.endDate))
        ?.length
    }
  },
  methods: {
    onAddButtonClick() {
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    clearForm() {
      for (const [key] of Object.entries(this.form)) {
        this.form[key] = null
      }
      this.form.change_vacation_hour = false
      this.form.onPprobation = false
      this.currentContractId = null;
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.checkMergeDates) {
        this.$showErrorToast('You have an intersection between dates');
        return
      }
      if (validateForm({
        startDate: this.form.startDate,
        endDate: this.form.endDate,
      })) {
        const data = {
          employee_uid: this.employeeId,
          end_date: this.form.endDate,
          start_date: this.form.startDate,
          on_probation: this.form.onPprobation ? 1 : 0,
          total_vacation_hours: this.form.total_vacation_hours ? this.form.total_vacation_hours : '',
          change_vacation_hour: this.form.change_vacation_hour ? 1 : 0,
            type: this.form.type
        }
        if (this.form.archivedDate) {
          data.archived_at = this.form.archivedDate;
        }
        if (this.formMode === FORM_MODE.CREATE) {
          this.$store.dispatch('employees/createContract', this.$convertObjectToFormData(data));
        } else {
          this.$store.dispatch('employees/updateContract', {
            id: this.currentContractId,
            data: this.$convertObjectToFormData({
              ...data,
              _method: 'PUT'
            })
          });
          this.currentContractId = null;
        }
        this.$bvModal.hide(this.modalId);
      }
    },
    onDismissIconClick(item) {
      this.dismissData = item
      setTimeout(() => {
        this.$bvModal.show('modal-dismiss');
      }, 10)
    },
    onEditIconClick(item) {
      this.form.startDate = item.start_date;
      this.form.endDate = item.end_date;
      this.form.archivedDate = item.archived_at;
      this.form.onPprobation = item.on_probation;
      this.form.total_vacation_hours = item.vacation_hours?.total_value
      this.form.type = item.type

      this.formMode = FORM_MODE.EDIT;
      this.currentContractId = item.id;
      this.$bvModal.show(this.modalId);
    },
    onShowIconClick(item) {
      this.dismissData = item
      setTimeout(() => {
        this.$bvModal.show('modal-dismiss');
      }, 10)
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this contract?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then((value) => {
          if (value) {
            this.$store.dispatch('employees/deleteContract', {
              id,
              data: this.$convertObjectToFormData({ _method: 'DELETE'})
            });
          }
        })
    },
    status(item) {
      const today = new Date(new Date(Date.now()).toISOString().slice(0, 10))
      if (!item.dismissed_date && today <= new Date(item.end_date) && today >= new Date(item.start_date)) {
        return 'Active'
      } else if (item.dismissed_date) {
        return 'Dismiss date: ' + this.$moment(item.dismissed_date).format('DD.MM.YYYY')
      } else if (today < new Date(item.start_date)) {
        return 'Inactive'
      } else {
        return 'Expired'
      }
    },
    getDateTo () {
      return (date) => {
        if (date < new Date(this.form.startDate)) {
          return true
        }
        return false
      }
    },
    isStartContractDayDisabled(date) {
      const index = this.currentContractId ? this.list.findIndex(({ id }) => id === this.currentContractId) : this.list.length;
      if (this.list.length && index !== 0) {
        const endDate = this.list[index - 1].dismissed_date ?? this.list[index - 1].end_date;
        return date <= new Date(endDate);
      }
      return false;
    }
  },
  watch: {
    'form.startDate' (val) {
      if (!val) {
        this.form.endDate = null
      }
    }
  }
}
</script>

<style>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
