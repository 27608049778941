<template>
  <div>
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
      @hidden="clearForm"
    >
      <b-form
        @submit="handleSubmit"
      >
        <b-form-group
          label="Title"
          label-for="title"
          class="required"
        >
          <b-form-input
            id="title"
            v-model="form.title"
            type="text"
            maxlength="255"
            placeholder="Enter title"
            required
          />
        </b-form-group>
        <b-form-group
          label="Link"
          label-for="link"
          class="required"
        >
          <b-form-input
            id="link"
            v-model="form.link"
            type="text"
            maxlength="255"
            placeholder="Enter link"
            required
          />
        </b-form-group>
        <b-form-group
          label="Note"
          label-for="note"
          class="required"
        >
          <b-form-textarea
            id="note"
            v-model="form.note"
            type="text"
            placeholder="Enter note"
            required
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
          >
            {{ buttonText }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h4>Documents</h4>
      <b-button variant="primary" @click="onAddButtonClick">Add Document</b-button>
    </div>
    <div
      v-if="documents && documents?.length > 0"
      class="table-responsive"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th class="id-column"><div>#</div></th>
            <th>Title</th>
            <th>Note</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in documents"
            :key="item.id"
          >
            <th class="id-column"><div>{{ index + 1 }}</div></th>
            <td class="title"><a target="_blank" :href="item.link">{{ item.title }}</a></td>
            <td><div v-html="note(item.note)"></div></td>
            <td class="actions-buttons">
              <div>
                <i
                  class="bx bx-pencil text-warning font-size-20 mr-3"
                  @click="onEditIconClick(item)"
                />
                <i
                  class="bx bx-trash text-danger font-size-20"
                  @click="onDeleteIconClick(item.id)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { FORM_MODE } from '@/utils/constants';
import { validateForm } from '@/utils/validation';
import {
  convertDatesToRange,
} from '@/utils/converters';

export default {
  name: 'Contracts',
  props: ['employeeId', 'contracts', 'documents'],
  components: {
  },
  data() {
    return {
      formMode: null,
      currentDocumentId: null,
      modalId: 'document-modal',
      form: {
        title: '',
        link: '',
        note: '',
      },
      convertDatesToRange
    }
  },
  computed: {
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Document' : 'Edit Document';
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Create' : 'Save';
    },
  },
  methods: {
    onAddButtonClick() {
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    clearForm() {
      this.form.title = '';
      this.form.link = '';
      this.form.note = '';

      this.currentDocumentId = null;
    },
    async handleSubmit(event) {
      event.preventDefault();
      if (validateForm({
        title: this.form.title,
        note: this.form.note,
        link: this.form.link
      })) {
        const data = {
          title: this.form.title,
          link: this.form.link,
          note: this.form.note,
          employee_uid: this.$route.params.id
        }
        let isSuccessLoad = true
        if (this.formMode === FORM_MODE.CREATE) {
          await this.$store.dispatch('employees/createDocument', this.$convertObjectToFormData(data))
            .then((response) => {
              if (!response) {
                isSuccessLoad = false
              }
            });
        } else {
          await this.$store.dispatch('employees/updateDocument', {
            id: this.currentDocumentId,
            data: this.$convertObjectToFormData({
              ...data,
              _method: 'PUT'
            })
          })
            .then((response) => {
              if (!response) {
                isSuccessLoad = false
              }
            });
          this.currentDocumentId = null;
        }
        if (isSuccessLoad) {
          this.$bvModal.hide(this.modalId);
          this.$store.dispatch('employees/getEmployeeById', this.$route.params.id);
        }
      }
    },
    onEditIconClick(item) {
      this.form.title = item.title;
      this.form.link = item.link;
      this.form.note = item.note;

      this.formMode = FORM_MODE.EDIT;
      this.currentDocumentId = item.id;
      this.$bvModal.show(this.modalId);
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this document?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch('employees/deleteDocument', {
              id,
              data: this.$convertObjectToFormData({ _method: 'DELETE'})
            });
            await this.$store.dispatch('employees/getEmployeeById', this.$route.params.id);
          }
        })
    },
    note (note) {
      return note?.split('\n' || '\r').join('<br>')
    },
  }
}
</script>

<style scoped>
.title {
  width: 300px;
}
@media (max-width: 768px) {
  .title {
    width: 200px;
  }
}
</style>
