<template>
  <b-list-group>
    <b-list-group-item
      v-for="book in books"
      :key="book.id"
      class="d-flex"
    >
      <div class="book-img mr-3">
        <img :src="book.photo" alt="" />
      </div>
      <div>
        <div class="mb-1"><span class="mr-1 text-dark">Title:</span>{{ book.title }}</div>
        <div class="mb-1"><span class="mr-1 text-dark">Author:</span>{{ book.author }}</div>
        <div class="mb-1"><span class="mr-1 text-dark">Category:</span>{{ book.category }}</div>
        <div><span class="mr-1 text-dark">Taken:</span>{{ $moment(book.taken).format('DD.MM.YYYY') }}</div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>

export default {
  name: 'LibraryTab',
  props: ['books']
}
</script>

<style lang="scss" scoped>
  .book-img {
    width: 90px;
    height: 120px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }
</style>
